<template>
  <div class="page-gray addressList">
    <div class="list" id="addressList">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          ref="list"
          @load="onLoad"
      >
        <transition-group name="flip-list" tag="div">
          <div class="item" v-for="(item, index) in tableData" :key="index" :id="`address${item.id}`">
            <div class="top" @click="chooseAdderss(item)">
              <div class="user">
                <div class="name">{{ item.receiverName}}</div>
                <div class="phone">{{ item.phone}}</div>
              </div>

              <div class="address">{{ item.province+item.city+item.district+item.address}}</div>
            </div>

            <div class="bottom">
              <div class="default">
                <div class="radio" v-if="defaultAddress !== item.id" @click="changeDefault(item, index)"></div>
                <img class="radio_check" src="./../../assets/images/address/check.png" v-else/>
                <span class="radio_text">默认地址</span>
              </div>

              <div class="handle">
                <div class="edit" @click="$router.push(`/address/edit/${item.id}`)">
                  <img src="./../../assets/images/address/edit.png" alt="">
                  <span>编辑</span>
                </div>
                <div class="del" @click="delAddress(item, index)">
                  <img src="./../../assets/images/address/del.png" alt="">
                  <span>删除</span>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </van-list>
    </div>

    <div class="btn" @click="$router.push('/address/add')">
      <van-button type="primary" block>添加收货地址</van-button>
    </div>
  </div>
</template>

<script>
// import { gsap } from 'gsap';
import Vue from "vue";

export default {
  name: "AddressLists",
  data() {
    return {
      timer: null,
      defaultAddress: '',
      loading: false,
      finished: false,
      list: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      //加载数据
      tableData:[],
    }
  },
  activated(){
    this.tableData = [];
    this.finished = false;
  },
  methods: {
    chooseAdderss(item) {
      if (this.$route.query.type === 'choose') {
        // 在订单提交数据中的onActive解析
        localStorage.setItem('selectAddress', JSON.stringify(item))
        this.$router.back();
      }
    },

    goTop() {
      // 由快到慢  （每次开启定时器都重新计算速度）
      var timer = setInterval(function () {
        let osTop = document.querySelector('#addressList').scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.querySelector('#addressList').scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    changeDefault(item, index) {
      this.$dialog.confirm({
        title: '提示',
        message: `确认默认该地址？`,
      }).then(() => {
        console.log(1)
        this.$http.post(this.baseMallUrl + `/h5_mall/defaultAddress/${item.id}`, {emulateJSON: true}).then(() => {
          this.$toast.success('操作成功');
          this.tableData = [];
          this.finished = false;
        })
      }).catch(() => {
        this.tableData = [];
        this.finished = false;
      });
      this.defaultAddress = item.id;

      let selectItem = this.list.splice(index, 1);
      this.list.unshift(selectItem[0])

      this.goTop()

    },
    delAddress(item) {
      this.$dialog.confirm({
        title: '提示',
        message: `是否删除该地址`,
      }).then(() => {
        // TODO SEVER
        this.$http.post(this.baseMallUrl + `/h5_mall/delBuyerUserAddress/${item.id}`, {emulateJSON: true}).then(() => {
          this.$toast.success('删除成功');
          this.tableData = [];
          this.finished = false;
        })
      }).catch(() => {

      });
    },
    onLoad() {
      let form = {
        deptId:Vue.prototype.deptId
      }
      this.getList(this.page,form)
    },
    //分页
    getList(page, params) {
      this.loading = true
      this.$http.get(this.baseMallUrl + "/h5_mall/queryBuyerReceiverList",{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize
        }, params)},{
        emulateJSON:true
      }).then(response => {
        // this.tableData = this.tableData.concat(response.data.records)
        this.tableData = response.data.records
        if(this.tableData){
          this.defaultAddress = this.tableData[0].id;
        }
        this.page.total = response.data.total
        if(this.tableData.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.finished = true;
      })
    },
  },


}

</script>
<style lang="less" scoped>
.addressList {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .list {
    flex: 1;
    overflow: auto;
  }

  .btn {
    padding: 11px 18px;
  }
}

.item {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  margin: 18px;

  &:first-child {
    margin-top: 10px;
  }

  margin-bottom: 0;

  .top {
    padding: 20px 33px 13px 20px;

    .user {
      display: flex;

      .name {
        line-height: 15px;
        font-size: 15px;
        color: #3C3C3C;
      }

      .phone {
        font-size: 14px;
        color: #3C3C3C;
        line-height: 14px;
        margin-left: 22px;
      }
    }

    .address {
      font-size: 12px;
      color: #AAAAAA;
      line-height: 16px;
      margin-top: 13px;
    }
  }

  .bottom {
    display: flex;
    padding: 11px 18px 18px 22px;
    justify-content: space-between;
    border-top: 1px solid #d7d7d7;

    .default {
      height: 16px;
      display: flex;
      align-items: center;

      .radio {
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 50%;
        margin-right: 9px;
      }

      .radio_check {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 9px;
      }

      .radio_text {
        font-size: 12px;
        color: #333333;
        line-height: 12px;
      }
    }

    .handle {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      margin-right: -14px;

      > div {
        display: flex;
        align-items: center;

        &:first-child {
          margin-right: 14px;
        }
      }

      img {
        height: 14px;
        margin-right: 6px;
      }

      span {
        font-size: 12px;
        color: #3C3C3C;
        line-height: 12px;
        width: 40px;
      }
    }
  }

}

.flip-list-move {
  transition: transform .4s;
}
</style>
